// Vidyard analytics - sends events to GA4 https://knowledge.vidyard.com/hc/en-us/articles/360009997413#vidyards-progress-events-script-ga4

function initApp() {

    vidyardEmbed.api.addReadyListener(function(data, player) {
        var video_embed_url = window.location.href; // defines url where video is embedded

        // ****** PROGRESS EVENTS ******     
        vidyardEmbed.api.progressEvents(function(result) {

            // defines the video URL, video duration, and video title
            var uuid = result.player.uuid;
            var video_url = "https://play.vidyard.com/" + uuid;
            var video_duration = result.player.metadata.chapters_attributes[result.chapter].video_attributes.length_in_seconds;
            var video_title = result.player.metadata.chapters_attributes[result.chapter].video_attributes.name;

            // Function for sending milestone data to GA
            // the data we send is the same for start, complete, and progress, but with a different event name for each
            function sendProgressToGA(name) {
                gtag('event', name, {
                    'video_current_time': (result.event / 100) * video_duration,
                    'video_duration': video_duration,
                    'video_percent': result.event, // this will be 0 for video_start, and 100 for video_complete
                    'video_provider': "Vidyard",
                    'video_title': video_title,
                    'video_url': video_url,
                    'video_embed_url': video_embed_url
                });
            }

            if (result.event === 0) { // video has been started
                // console.log("video " + video_title + "has been started");
                // send data to GA for video start
                sendProgressToGA("video_start");

            } else if (result.event === 100) { // video has been completed
                // console.log("video has been completed");
                // send data to GA for video complete
                sendProgressToGA("video_complete");

            } else { // video has reached another milestone (ie. 25%, 50%, etc.)
                // console.log("video has reached " + result.event + "%");
                // send data to GA for video progress
                sendProgressToGA("video_progress");
            }
        }, [0, 10, 25, 50, 75, 95, 100]); // milestones at which events fire
    });
}

window.vidyardEmbed ?
    initApp(window.vidyardEmbed) :
    (window.onVidyardAPI = function(vyApi) {
        initApp(vyApi);
    });